<template>
  <div class="box">
    <div class="search">
      <div class="search-top">
        <div v-for="(item, i) in toplist" :key="i">{{ item.name }}（{{ item.value }}）</div>
      </div>
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="商家信息：" prop="contactName">
              <el-input v-model="from.businessName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="合同状态：" prop="contactName">
              <!-- <el-input v-model="from.status" placeholder="请输入" clearable></el-input> -->
              <el-select class="input" v-model="from.status" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签署时间：" prop="contactName">
              <el-date-picker @change="change" v-model="valuechange" type="daterange" start-placeholder="开始日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                end-placeholder="结束日期" />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="display: flex; justify-content: right;padding-right: 12px;">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tab">
      <div class="add">
        <el-button type="primary" @click="added">新增合同</el-button>
        <el-button type="primary" @click="focus">发起合同</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" show-summary height="calc(100vh - 400px)" border v-loading="loading"
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">



        <el-table-column prop="businessName" label="商家名称" align="center">
        </el-table-column>
        <el-table-column prop="status" label="合同状态" align="center">
          <template slot-scope="scope">
            <el-button type="danger" v-if="scope.row.status == 1" plain>待签订</el-button>
            <el-button type="primary" v-if="scope.row.status == 2" plain>已生效</el-button>
            <el-button type="info" v-if="scope.row.status == 3" plain>已取消</el-button>
            <!-- <el-button type="primary" v-else plain>待签订</el-button> -->
          </template>
        </el-table-column>
        <el-table-column prop="endDate" label="合同到期日" align="center">
          <!-- <template slot-scope="scope">
            <div>{{ scope.row.contractStartDate }} &nbsp;-&nbsp; {{ scope.row.contractEndDate }}</div>
          </template> -->

        </el-table-column>
        <el-table-column prop="creatTime" label="签署时间" align="center">
        </el-table-column>


        <el-table-column prop=" " label="操作" width="250" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="go(scope.row)">查看合同</el-button>
            <el-button type="text" v-if="scope.row.status == 3" @click="revoke(scope.row)">重新发起</el-button>
            <el-button type="text" v-else style="color: red;" @click="revoke(scope.row)">撤销</el-button>
          </template>
        </el-table-column>


      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="新增合同" :visible.sync="showDialog" width="600px" height="1000px">
      <div class="dia">
        <el-form label-width="120px" label-position="left">
          <el-row>
            <!-- contractName -->
            <el-col :span="16">
              <el-form-item label="商家名称">
                <el-input @focus="focus('fo')" v-model="fo.businessName" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="合同周期">
                <el-date-picker @change="changezq" style="width: 100%;" v-model="valuezq" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                  end-placeholder="结束日期" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="团购佣金比例">
                <el-input v-model="fo.buyRake" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="外卖佣金比例">
                <el-input v-model="fo.takeawayRake" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="到店扫码佣金比例">
                <el-input v-model="fo.yardRake" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="账期(天)">
                <div class="liname"></div>
                <el-input v-model="fo.accountTerm" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="16">
              <el-form-item label="附件">
                <el-upload class="upload-demo" :action="$uploadURL" :on-success="handleChange"
                  :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple
                  :limit="1" :on-exceed="handleExceed" :file-list="fileList">
                  <el-button size="small" type="primary">合同上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="but">
          <el-button size="medium" style="margin-right: 40px;" @click="showDialog = false">取消 </el-button>
          <el-button size="medium" type="primary" @click="bc()">保存 </el-button>
        </div>

      </div>
    </el-dialog>
    <!-- <el-dialog title="发起合同" :visible.sync="showstartDialog" width="600px" height="1000px">
      <div class="dia">
        <el-form label-width="120px" label-position="left">
          <el-row>
            <el-col :span="16">
              <el-form-item label="商家名称">
                <el-input @focus="focus" v-model="formS.businessName" type="text" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="but">
          <el-button size="medium" style="margin-right: 40px;" @click="showstartDialog = false">取消 </el-button>
          <el-button size="medium" type="primary" @click="send()">确定</el-button>
        </div>

      </div>
    </el-dialog> -->
    <el-dialog title="发起合同" :visible.sync="businessDialog" width="500px" height="400px">
      <el-form label-width="80px" label-height="40px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商家名称：" prop="contactName">
              <el-input v-model="bform.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex; justify-content: right;">
           
            <el-button type="primary" @click="searchsj" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
      <div class="dia">
        <el-table :data="restaurants" style="width: 100%" height="calc(100vh - 480px)"
          @selection-change="handleSelectionChange" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
          <el-table-column type="selection" width="50"> </el-table-column>


          <el-table-column prop="businessName" label="商家名称" align="center">
          </el-table-column>
         
          <el-table-column prop="creatTime" label="创建时间" align="center">

          </el-table-column>


        </el-table>


        <div class="diafour" style="text-align: center;">
          <el-button type="danger" @click="businessDialog = false">取消</el-button>
          <el-button type="primary" @click="send">确定 </el-button>
        </div>
      </div>

    </el-dialog>
    <el-dialog title="查看合同" :visible.sync="showCDialog" min-width="800px" width="1000px" height="600px">
      <div class="view-box">
        <div class="imgli" v-for="(item,index) in showCImgList" :key="index">
          <img :src="item" alt="">
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      toplist: [{
        name: '全部',
        value: ''
      }, {
        name: '已签署',
        value: ''
      }, {
        name: '未签署',
        value: ''
      }],
      tableData: [{
        name: 'wdz'
      }],
      from: {
        businessName: ''
      },
      fo: {

      },
      formS:{},
      bform:{},
      focusKey: null,
      showDialog: false,
      showstartDialog: false,
      businessDialog: false,
      showCDialog: false,
      showCImgList: [],
      loading: false,
      restaurants:[],
      options: [{
        value: 1,
        label: '待签订'
      }, {
        value: 2,
        label: '已生效'
      }, {
        value: 3,
        label: '已取消'
      }],
      value: '',
      value1: '',
      fileList: [],
      queryInfo: {
        condition: {

        },
        currPage: 1,
        pageCount: 1,
        pageSize: 10

      },
      total: 0,
      valuechange: '',
      valuezq: '',
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    added() {
      this.showDialog = true
    },
    //获取数据
    getList() {
      var that = this;
      this.loading = true
      that.$http.post("/businessContract/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        that.loading = false
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
      that.$http.post("/businessContract/queryCount").then(function (response) {
        that.loading = false
        if (response.data.code == 200) {
          that.toplist[0].value = response.data.data.count;
          that.toplist[1].value = response.data.data.signed
          const _notSigned = Number(response.data.data.count) - Number(response.data.data.signed)
          that.toplist[2].value = _notSigned//response.data.data.notSigned;
        }
      });
    },
    search() {
      this.queryInfo.currPage = 1;
      this.queryInfo.condition = this.from
      this.getList()
    },
    reset() {
      this.queryInfo.condition = {}
      this.from.businessName = ''
      this.from.startDate = ''
      this.valuechange = ''
      this.queryInfo.currPage = 1;
      this.getList()
    },
    change() {
      console.log(this.valuechange);
      this.from.startDate = this.valuechange[0]//this.newDate()
      // this.from.endDate = this.newDate(this.valuechange[1])
    },
    changezq() {
      console.log(this.valuezq);
      this.fo.startDate = this.newDate(this.valuezq[0])
      this.fo.endDate = this.newDate(this.valuezq[1])
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    handleChange(response, file, fileList) {
      console.log(file, fileList, response, 'PPP');
      this.fo.contractFile = response.data.url
    },
    handlePreview(){},
    beforeRemove(){},
    handleRemove(){},
    handleExceed(){},
    bc() {
      var that = this;
      this.loading = true
      that.$http.post("/businessContract/signContract", that.fo).then(function (response) {
        console.log(response.data.data);
        that.loading = false
        if (response.data.code == 200) {
          that.showDialog = false
          that.getList()
        }
      });

    },
    go(row) {
      console.log(row);
      this.showCImgList = []
      this.showCImgList = row.contractFile.split(',')
      if(this.showCImgList<=0){
        this.$message.warning('合同待签定！')
        return
      }
      this.showCDialog = true
    },
    startC(){
      this.showstartDialog = true
    },
    focus(val){
      this.focusKey = val
      this.businessDialog = true
      var that = this;
      that.$http.post('/business/queryBusinessList',{}).then(response => {
        that.restaurants = response.data.data
      })
    },
    searchsj(){
      var that = this;
      that.$http.post('/business/queryBusinessList',{
        businessName:that.bform.name
      }).then(response => {
        that.restaurants = response.data.data
      })
    },
    handleSelectionChange(val){
      if(this.focusKey == 'fo'){
        this.fo.businessId = val[0].id
        this.fo.businessName = val[0].businessName
      }else{
        this.formS.businessId = val[0].id
        this.formS.businessName = val[0].businessName
      }
      console.log(val,this.fo,this.formS);
      // that.$http.post("/commodityType/queryCommodityTypeList", {
      //   businessId: val[0].id,
      //   classifyLevel: 1
      // }).then(function (response) {
      //   console.log(response.data.data);
      //   if (response.data.code == 200) {
      //     that.taplist = response.data.data
      //   }
      // });
    },
    send(row){
      var that = this;
      if(this.focusKey == 'fo'){
        that.businessDialog = false
      }else{
        this.loading = true
        that.formS.status = 1
        that.$http.post("/businessContract/save", that.formS).then(function (response) {
          console.log(response.data.data);
          that.loading = false
          if (response.data.code == 200) {
            that.showstartDialog = false
            that.businessDialog = false
            that.getList()
          }
        });
      }
    },
    revoke(row){
      const _data = {
        businessId: row.businessId,
        id: row.id,
      }
      if(row.status == 2){
        _data.status = 3
        this.updataC(_data)
      }
      if(row.status == 3){
        _data.status = 2
        this.updataC(_data)
      }
      if(row.status == 1 || row.status == null){
        this.delC(_data)
      }
    },
    //变更状态
    updataC(data){
      var that = this;
      that.loading = true
      that.$http.post("/businessContract/update", data).then(function (response) {
        console.log(response.data.data);
        that.loading = false
        if (response.data.code == 200) {
          that.getList()
        }
      });
    },
    //撤销删除
    async delC(data){
      var that = this;
      const confirmResult = await that
        .$confirm("撤销将删除待签定的合同，确定撤销吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消",
          showClose: true,
        });
      } else {
        that.loading = true
        that.$http.post("/businessContract/delete", data).then(function (response) {
          that.loading = false
          if (response.data.code == 200) {
            that.getList()
          }
        });
      }
    },
    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 15px 10px;
    padding-bottom: 0;

    .search-top {
      padding: 10px;
      background-color: rgba(30, 152, 215, 0.14);
      font-size: 15px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      color: #3198ff;
      div {
        width: 150px;
        text-align: center;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 130px;
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }

  .diatwo {
    box-sizing: border-box;
    padding: 0 30px;

    .dialist {
      display: flex;
      flex-wrap: wrap;

      .dia-li {
        width: 300px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .name {
          font-weight: 700;
          margin-right: 20px;

        }

        img {
          width: 48px;
          height: 47px;
        }
      }
    }

    .diaimg {
      display: flex;
      flex-wrap: wrap;

      .imgbox {
        width: 300px;
        margin-bottom: 15px;

        .name {
          font-weight: 700;

        }

        .imgli {
          display: flex;
          margin-top: 15px;

          img {
            width: 92px;
            height: 65px;
            margin-right: 10px;
          }
        }
      }
    }

    .sh {
      display: flex;
      margin-top: 40px;

      textarea {
        width: 679px;
        height: 60px;
        margin-left: 10px;
      }

    }

  }
  .but {
      margin-left: 50%;
      transform: translateX(-40%);
    }

}
.view-box{
  flex: 1;
  // height: 540px;
  min-height: 540px;
  max-height: calc(85vh - 180px);
  overflow-y: auto;
  .imgli{
    flex: 1;
    img{
      width: 100%;
      object-fit: contain;
    }
  }
}
::-webkit-scrollbar-thumb{
    background: #3198ff;
  }
</style>